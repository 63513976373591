//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 768px;
$breakpoint-pc: 1200px;

//  size
//----------------------------------------------------------------------
$page-width: 1200px;
$main-width: 930px;
$side-width: 250px;

//  colors
//----------------------------------------------------------------------
$blue: #071985;
$orange: #df6d00;
$red: #e60012;
$border-gray: #e5e5e5;

$blue-light: lighten(#d5ecad, 10%); //仮
$orange-light: lighten(#fcf6c4, 10%); //仮

//  images
//----------------------------------------------------------------------
$pass: "../images/top/";
//background: url(#{$pass}○○.jpg);

//  font
//----------------------------------------------------------------------
@mixin fa {
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//  font
//----------------------------------------------------------------------
@mixin icons($code) {
  content: $code;
  font-family: "icons";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//  font vwバージョン
//----------------------------------------------------------------------
@function get_vw($size, $viewport: 375){
	$rate: 100 / $viewport;
	@return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
	font-size: $font_size * 1px;
	font-size: get_vw($font_size);
}