@import '_config';
@import '_sprite';

/*
greetingHeader

Markup:
<header class="greetingHeader">
	<p class="greetingHeader_logo"><img src="../assets/images/top/header_logo.png" alt="株式会社ケーメックス"></p>
</header>
============================================================*/
.greetingHeader {
	border-top: solid 3px $red;
	padding: 20px 0;
	&_logo {
		margin-top: 0;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp){
 	.greetingHeader {
		padding: 20px 0;
		&_logo {
			width: 200px;
			margin: 0 auto;
		}
	}
 }

/*
greetingFooter

Markup:
<footer class="greetingFooter">
	<p class="greeting_pageTop"><a href="#">このページトップへ戻る</a></p>
	<p class="greetingFooter_logo"><img src="" alt=""></p>
	<p class="greetingFooter_copyright">コピーライト</p>
</footer>
============================================================*/
.greetingFooter {
	position: relative;
	padding: 20px 0;
	border-top: solid 3px $red;
	&_logo {
		margin-top: 0;
		text-align: center;
	}
	&_copyright {
		text-align: center;
		font-size: 1.2rem;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.greetingFooter {
		padding: 50px 0 20px;
	}
}
.greeting {
	&_pageTop {
		font-size: 1.2rem;
		line-height: 33px;
		margin-top: 0;
		width: 220px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 25px;
		box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.16);
		background: linear-gradient(to top, #ffffff, #eeefef);
		a {
			display: block;
			&:before {
			    @include fa();
			    content: '\f0d8';
			    font-size: 1.2rem;
			    width: 15px;
			    line-height: 15px;
			    display: inline-block;
			    color: #fff;
			    background: linear-gradient(to top, #930909, #c70000);
			    margin-right: 5px;
			    border-radius: 50%;
			}
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.greeting {
		&_pageTop {
			right: 0;
			left: 0;
			margin: 0 auto;
		}
	}
}