@import '_config';
@import '_sprite';

/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 3.
*/
.headLine4 {
	font-weight: 700;
    font-size: 1.8rem;
    background-color: #f3f3f3;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 40px 0 20px;
    word-break: break-all;
    @media screen and (max-width: $breakpoint-sp){
        @include fz_vw(16);
    }
}
@media screen and (max-width: $breakpoint-sp){
	.headLine4 {
        padding: 5px 10px;
		margin: 30px 0 20px;
	}
}