@import '_config';
@import '_sprite';

/*============================================================
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
============================================================*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	line-height:1;
}
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
blockquote, q {
	quotes:none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}
a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}
/* change colours to suit your needs */
ins {
	text-decoration:none;
}
/* change colours to suit your needs */
mark {
}
del {
	text-decoration: line-through;
}
abbr[title], dfn[title] {
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
/* change border colour to suit your needs */
hr {
	display:block;
	height:1px;
	border:0;
	border-top:1px solid #cccccc;
	margin:1em 0;
	padding:0;
}
sub,
sup {
	position: relative;
	font-size: .75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}
input, select {
	vertical-align:middle;
}
// li {
// 	display: block;
// }
input[type="check"],
input[type="radio"] {
	margin: 0;
	vertical-align: middle;
	margin: 0 5px 0 0;
}

/* 再定義
-------------------------------------------------------------*/
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	// font-family: 'メイリオ',Meiryo, 'ヒラギノ角ゴ Pro','Hiragino Kaku Gothic Pro', Verdana,Arial,sans-serif,'ＭＳ Ｐゴシック';
	font-family: 'Noto Sans JP', sans-serif;
	font-size:14px;
	line-height:1.6;
	color:#333;
	white-space: normal;
	text-align:left;
	-webkit-text-size-adjust: 100%;
	overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
img {
	vertical-align:bottom;
}
hr { margin: 20px 0 0; }
p,ul,ol,table,dl,figure {
	margin-top:20px;
}
b,strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
button {
	font-family: inherit;
}
// input[type="text"],
input[type="password"],
select,
textarea {
	width: 100%;
	font-family: inherit;
}
table {
	width: 100%;
}
th,
td {
	text-align: left;
}

/* 追加共通
-------------------------------------------------------------*/
html {
	font-size: 62.5%;
} /* 10px */
body {
	font-size: 1.6rem;
} /* 16px */
a {
	text-decoration: none;
	word-break: break-all;
	color: #333333;
}
a:hover {
	opacity: 0.8;
	filter: alpha(opacity=80);
	-moz-opacity:0.8;
}
img {
    vertical-align: bottom;
    max-width: 100%;
    //max-height: 100%;
    //width: auto; ウィジウェグ対応用に追加
	// height: auto; ウィジウェグ対応用に追加
	-webkit-backface-visibility: hidden; //chromでの画像のぼやけ

	//2020.11 ウィジウェグ対応用に追加
    max-height: 100%;
    height: auto;
}
p {
	word-break: break-all;
}
textarea {
    overflow: auto;
    resize: vertical;
}
ul:not([class]),
ol {
	margin-left: 2em;
	& > :first-child {
		margin-top: 0;
	}
	li {
		display: list-item;
	}
}
th, td {
    text-align: left;
    border: 1px solid #b2b2b2;
    background-clip: padding-box;
    word-break: break-all;
    padding: 10px 15px;
    & > :first-child {
		margin-top: 0;
	}
}
button {
	border: none;
}

// 画面幅でのスクロール表示
body {
	min-width: 1200px;
}
.wrapper {
	width: 100%;
	min-width: 1200px;
	display: flex;
	display: -webkit-flex;
    flex-flow: column;
    min-height: 100vh;
}
.contents {
	flex: 1 0 auto;
}
@media screen and (max-width: $breakpoint-pc){
	body {
		overflow-x: scroll;
	}
}

@media screen and (max-width: $breakpoint-sp){
	body {
		overflow-x: hidden !important;
		min-width: auto;
	}
	.wrapper {
		min-width: auto;
	}
	input[type=text], select {
		font-size: 16px;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.editor {
		p iframe {
			width: 100%;
			height: auto;
		}
		video {
		    width: 100%;
		    height: auto;
		}
	}
	.youtube {
		position: relative;
		padding-top: calc(100% * 360 / 640);
		margin-top: 20px;
		width: 100%;
		height: 0;
		overflow: hidden;
		& > iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
