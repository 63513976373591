@font-face {
  font-family: 'icons';
  src:
    url('../fonts/icons.ttf?1rqyau') format('truetype'),
    url('../fonts/icons.woff?1rqyau') format('woff'),
    url('../fonts/icons.svg?1rqyau#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-size: inherit;
}

[class^="icons"], [class*=" icons"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


$icons-minus: "\e900";
$icons-plus: "\e901";
$icons-cross: "\e902";
$icons-arrowBold-right: "\e904";
$icons-circleArrow-right: "\e905";
$icons-arrow-right: "\e906";
$icons-delta-right: "\e907";