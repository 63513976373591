@import '_config';
@import '_sprite';


/*
パーツ

Markup:
<div class="topContents">
	<p class="commonTitle">テキストテキスト</p>
	<ul class="topField_list">
		<li class="topField_item">
			<dl class="topField_set">
				<dt class="topField_setTitle">タイトルタイトツ</dt>
				<dd class="topField_setText">テキストテキスト</dd>
				<dd class="topField_setBtn"><a href="#">詳しくはこちら</a></dd>
			</dl>
		</li>
============================================================*/
.greetingMain {
	position: relative;
	padding: 100px 0;
	height: 600px;
	overflow: hidden;
	background-image: linear-gradient(180deg, #59cbff 0%, #9ff1ff 100%);
	video {
		position: absolute;
	    // left: 0;
	    // top: -40%;
	    // transform: scale(1.1);
	    // // transform: translate(-50%, -60%);
	    // width: 100%;
	    // opacity: .6;
	    // filter: brightness(150%) saturate(150%);
	    left: 50%;
	    top: 65%;
	    transform: scale(1.05) translate(-50%, -60%);
	    width: 100%;
	    opacity: .2;
	    filter: brightness(150%) saturate(200%);
	}
	&_block {
		padding: 30px 70px 50px;
		width: 860px;
		height: 370px;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background-color: rgba(255,255,255,0.5);
		display: flex;
		display: -webkit-flex;
		align-items: center;
		-webkit-align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
	}
	&_blockInner {

	}
	&_blockLead {
		margin-top: 0;
		font-weight: bold;
		font-size: 3.2rem;
		text-align: center;
	}
	&_blockText {
		line-height: 2;
		text-align: center;
	}
}
@media screen and (max-width: $breakpoint-sp){
	.greetingMain {
		padding: 50px 10px;
		height: auto;
		background: #fff url(#{$pass}video_bg.png) center center no-repeat;
		background-size:cover;
		video {
			display: none;
		}
		&_block {
			width: 100%;
			height: auto;
			position: static;
			padding: 30px 20px;
		}
		&_blockText {
			text-align: justify;
		}
	}
}
/*
パーツ

Markup:
<div class="greeting_body">
	<div class="greeting_unit">
		<div class="greeting_unitItem greeting_unitItem-even">
			<p class="greeting_unitLogo"><img src="../assets/images/top/unit_logo01.png" alt="ケーメックスオートメーション"></p>
			<p class="greeting_unitLead">株式会社ケーメックス・オートメーション</p>
			<h4 class="headLine4">事業内容</h4>
			<p class="greeting_unitText">LAPP, Murrelektronik, Murrplastik, Adaptaflex, binder 等の製品を輸入販売する商社</p>
			<p>〒101-0032<br>東京都千代田区岩本町2丁目3番3号　ザイマックス岩本町ビル 3階</p>
			<h4 class="headLine4">所在地</h4>
			<p>〒101-0032<br>東京都千代田区岩本町2丁目1番15号　吉安神田ビル 7階</p>
			<h4 class="headLine4">電話番号</h4>
			<p>03-3864-0888（代）</p>
			<p class="greeting_btn"><a href="#">サイトへ行く</a></p>
			<p class="greeting_makerTitle">取扱メーカー</p>
			<ul class="greeting_makers">
				<li class="greeting_maker"><a href="#"><img src="../assets/images/top/maker_img01.png" alt="メーカーロゴ"></a></li>
				<li class="greeting_maker"><a href="#"><img src="../assets/images/top/maker_img02.png" alt="メーカーロゴ"></a></li>
============================================================*/
.greeting {
	&_body {
		width: $page-width;
		margin: 50px auto 80px;
	}
	&_unit {
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	&_unitItem {
		padding: 40px 33px;
		border: solid 3px $border-gray;
		width: calc((100% - 60px)/2);
		&-even {

		}
		&-odd {

		}
	}
	&_unitLogo {
		line-height: 64px;
		margin-top: 0;
		text-align: center;
		a {
			display: block;
		}
	}
	&_unitLead {
		text-align: center;
		margin-top: 40px;
		font-size: 1.9rem;
		font-weight: bold;
	}
	&_btn {
		width: 400px;
		margin: 35px auto 0;
		a {
			display: block;
			border-radius: 5px;
			text-align: center;
			color: #fff;
			width: 100%;
			padding: 10px 8px;
			background-color: $red;
			box-shadow: 0 2px #d9d9d9;
			position: relative;
			&:before {
				position: absolute;
				right: 10px;
				top: 13px;
				@include icons($icons-arrowBold-right);
				font-size: 1rem;
			}
		}
	}
	&_makerTitle {
		margin-top: 40px;
		font-weight: bold;
		text-align: center;
		font-size: 1.9rem;
	}
	$gap-l: 35px;
	&_makers {
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		margin: -10px 0 0 -#{$gap-l};
	}
	&_maker {
		width: 140px;
		height: 52px;
		display: block;
		text-align: center;
		margin: #{$gap-l} 0 0 #{$gap-l};
		a {
			display: block;
		}
	}
	&_unitText {
		&-h {
			height: 50px;
		}
	}
}
@media screen and (max-width: $breakpoint-sp){
	.greeting {
		&_body {
			width: 100%;
			margin: 30px 0;
			padding: 0 10px;
		}
		&_unit {
			flex-wrap: wrap;
		    -webkit-flex-wrap: wrap;
		    justify-content: center;
		    -webkit-justify-content: center;
			// display: block;
		}
		&_unitItem {
			margin-top: 20px;
			width: 100%;
			padding: 30px 20px;
		}
		&_unitLogo {
			max-width: 200px;
			margin: 0 auto;
			line-height: auto;
		}
		&_btn {
			width: 100%;
		}
		$gap-l: 10px;
		&_makers {
			margin: 0 0 0 -#{$gap-l};
		}
		&_maker {
			width: calc((100% / 2) - #{$gap-l});
			margin: 20px 0 0 #{$gap-l};
		}
	}
}